import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [boats, setBoats] = useState([]); // Başlangıçta boş bir array
  const [searchTerm, setSearchTerm] = useState("");
  const [columns, setColumns] = useState(2);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [searchField, setSearchField] = useState("all");

  useEffect(() => {
    fetch("https://api.hpmarin.xyz/api/user/", {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBoats(data.data || []);
      })
      .catch((error) => {
        console.error("API hatası:", error);
      });
  }, []);

  const filteredBoats = boats
    .filter((item) => {
      const searchText = searchTerm.toLowerCase();
      const fields = [
        "tekneadi",
        "yetkiliadi",
        "motormodel",
        "motorserino",
        "jeneratormodel",
        "jeneratorserino",
        "sanzimanmodel",
        "sanzimanserino",
      ];

      if (searchField && fields.includes(searchField)) {
        return item[searchField].toLowerCase().includes(searchText);
      }
      return fields.some((field) =>
        item[field].toLowerCase().includes(searchText)
      );
    })
    .sort((a, b) => a.tekneadi.localeCompare(b.tekneadi));

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleFieldChange = (e) => setSearchField(e.target.value);
  const handleViewChange = (columnCount) => setColumns(columnCount);
  const highlightText = (text) => {
    if (!searchTerm) return text;
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) =>
      searchTerm.toLowerCase() === part.toLowerCase() ? (
        <span key={index} className="fosforla_skmim">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "hp" && password === "200518Fg.") {
      toast.success("Başarıyla giriş yaptınız!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoggedIn(true);
    } else if (username !== "hp") {
      toast.error("Hatalı kullanıcı adı!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Hatalı şifre!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleFocus = () => {
    window.scrollTo(0, 0);
  };

  if (!isLoggedIn) {
    return (
      <div className="login-screen">
        <ToastContainer />
        <div className="container d-flex justify-content-center align-items-center vh-100">
          <div className="card p-4" style={{ width: "300px" }}>
            <h3 className="text-center">Giriş Yap</h3>
            <form onSubmit={handleLogin}>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Kullanıcı Adı
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Şifre
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Giriş Yap
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <ToastContainer />
      <h1 className="text-center">HP MARİN TEKNE ARŞİV</h1>

      <div className="row justify-content-center mt-4">
        <div className="col-md-9 d-flex">
          <div className="row-md-3">
            <select
              className="form-select me-2"
              value={searchField}
              onChange={handleFieldChange}
            >
              <option value="all">Tümünde ara</option>
              <option value="tekneadi">Tekne Adı</option>
              <option value="yetkiliadi">Yetkili Adı</option>
              <option value="motormodel">Motor Model</option>
              <option value="motorserino">Motor Seri No</option>
              <option value="jeneratormodel">Jeneratör Model</option>
              <option value="jeneratorserino">Jeneratör Seri No</option>
              <option value="sanzimanmodel">Şanzıman Model</option>
              <option value="sanzimanserino">Şanzıman Seri No</option>
            </select>
          </div>

          {/* Arama kutusu */}
          <input
            type="text"
            className="form-control"
            placeholder="Arama yapın..."
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={handleFocus}
          />
        </div>
      </div>

      <div className="d-flex justify-content-start mt-3 icon-container">
        <span
          className={`view-icon ${columns === 3 ? "active-view" : ""}`}
          onClick={() => handleViewChange(3)}
        >
          <i className="bi bi-grid-3x2-gap"></i>
        </span>
        <span
          className={`view-icon ${columns === 2 ? "active-view" : ""}`}
          onClick={() => handleViewChange(2)}
        >
          <i className="bi bi-grid"></i>
        </span>
        <span
          className={`view-icon ${columns === 1 ? "active-view" : ""}`}
          onClick={() => handleViewChange(1)}
        >
          <i className="bi bi-list"></i>
        </span>
      </div>

      <div className="row mt-4">
        {filteredBoats.map((item, index) => (
          <div
            key={index}
            className={`col-md-${12 / columns} mb-4`}
            style={{ minHeight: "200px" }}
          >
            <div className="card h-100">
              <div className="card-body">
                <h5
                  className={`card-title ${
                    !item.tekneadi || item.tekneadi === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  {highlightText(item.tekneadi || "Bilinmiyor")}
                </h5>
                <p
                  className={`card-text ${
                    !item.yetkiliadi || item.yetkiliadi === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Yetkili Adı:</strong>{" "}
                  {highlightText(item.yetkiliadi || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.motormodel || item.motormodel === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Motor Model:</strong>{" "}
                  {highlightText(item.motormodel || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.motorserino || item.motorserino === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Motor Seri No:</strong>{" "}
                  {highlightText(item.motorserino || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.jeneratormodel || item.jeneratormodel === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Jeneratör Model:</strong>{" "}
                  {highlightText(item.jeneratormodel || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.jeneratorserino || item.jeneratorserino === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Jeneratör Seri No:</strong>{" "}
                  {highlightText(item.jeneratorserino || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.sanzimanmodel || item.sanzimanmodel === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Şanzıman Model:</strong>{" "}
                  {highlightText(item.sanzimanmodel || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.sanzimanserino || item.sanzimanserino === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Şanzıman Seri No:</strong>{" "}
                  {highlightText(item.sanzimanserino || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.musteriTelefonno || item.musteriTelefonno === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Telefon No:</strong>{" "}
                  {highlightText(item.musteriTelefonno || "Bilinmiyor")}
                </p>
                <p
                  className={`card-text ${
                    !item.servisformlari || item.servisformlari === "-"
                      ? "text-light-opacity"
                      : ""
                  }`}
                >
                  <strong>Servis Formları:</strong>{" "}
                  {highlightText(item.servisformlari || "Bilinmiyor")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
